<template id="informacionBloque">
  <section class="informacionBloque">
    <h1 class="hidden">Congreso Knotgroup</h1>
    <section class="informacion__imagen">
      <picture class="picture"> 
              <source srcset="https://congresoknotgroup.inspiriadental.com/imagenes/informacion/webp/cabecera-knotgroup.webp" type="image/webp">
              <img class="informacion__imagen__img" src="https://congresoknotgroup.inspiriadental.com/imagenes/informacion/jpg/cabecera-informacion-knotgroup.jpg" title="Cabecera informacion Knotgroup Los Orígenes" alt="Cabecera informacion Knotgroup Los Orígenes" loading="lazy">
      </picture>
    </section>
      <!-- Aqui tendríamos que tener un h1 -->
      <section class="informacion__origenes">
      <h2 class="informacion__origenes__h2">{{ losOrigenes.titulo }}</h2>
      <p
        v-for="info in losOrigenes.parrafos"
        :key="info"
        class="informacion__origenes__p"
      >
        {{ info }}
      </p>
    </section>
    <section class="informacion__comite">
      <h2 id="ancla_comiteCientifico" class="informacion__comite__h2">{{ comiteCientifico.titulo }}</h2>
      <div class="informacion__comite__contenedor">
        <div
          v-for="cientificos in comiteCientifico.organizadores"
          :key="cientificos"
          class="informacion__comite__grid"
        >
        <picture class="picture"> 
              <source :srcset="cientificos.img.urlw" type="image/webp">
              <img class="informacion__imagen__img" :src="cientificos.img.url" :alt="cientificos.img.alt" :title="cientificos.img.title" loading="lazy">
      </picture>
          <div class="textos">
              <img class="textos__fondo"
              src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/fotos/name.jpg"
            />
          <p class="textos__nombre">{{ cientificos.nombre }}</p>
          <p class="textos__titulo" 
          v-if="cientificos.titulo != ''">{{ cientificos.titulo }}</p>
          </div>
        </div>
      </div>
      <h2 id="ancla_comite" class="informacion__comite__h2">{{ comite.titulo }}</h2>
      <div class="informacion__comite__contenedor">
        <div
          v-for="organizadores in comite.organizadores"
          :key="organizadores"
          class="informacion__comite__grid"
        >
        <picture class="picture"> 
              <source :srcset="organizadores.img.urlw" type="image/webp">
              <img class="informacion__imagen__img" :src="organizadores.img.url" :alt="organizadores.img.alt" :title="organizadores.img.title" loading="lazy">
      </picture>
      <div class="textos">
       <picture class="picture">
              <source
                srcset="https://congresoknotgroup.inspiriadental.com/imagenes/ponentes/webp/imagen-fondo-nombre-knotgroup.webp"
                class="textos__fondo"
                type="image/webp"
                alt="Imagen Fondo Nombre Congreso Knotgroup Los Orígenes"
                title="Imagen Fondo Nombre Congreso Knotgroup Los Orígenes"
                loading="lazy">
              <img
                class="textos__fondo"
                src="https://congresoknotgroup.inspiriadental.com/imagenes/ponentes/jpg/imagen-fondo-nombre-knotgroup.jpg"
                alt="Imagen Fondo Nombre Congreso Knotgroup Los Orígenes"
                title="Imagen Fondo Nombre Congreso Knotgroup Los Orígenes"
                loading="lazy"
              />
            </picture>
          <p class="textos__nombre">{{ organizadores.nombre }}</p>
          <p class="textos__titulo" 
          v-if="organizadores.titulo != ''">{{ organizadores.titulo }}</p>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>

import {mapState} from 'vuex';
  export default {
    name: "informacion principal",
    props: {
      informacion: Object,
    },
    setup(props) {
      const informacion = props.informacion;
      return { informacion };
    },
    computed:{
        ...mapState('cambioImagenes',['infoUsuario']),
      },
    data() {
      return {
        losOrigenes: this.informacion.bloque_los_origenes,
        comite: this.informacion.bloque_comite_organizador,
        comiteCientifico : this.informacion.bloque_comite_cientifico,
      };
    },
    methods: {
      scrollIntoFrom() {
        if (
          document.referrer != "/informacion" &&
          ( 
            window.location.href.includes("ancla_comite") ||
            window.location.href.includes("ancla_comiteCientifico")
          )
        ) {
          setTimeout(() => {
            document
              .querySelector(window.location.hash)
              .scrollIntoView({ behavior: "smooth" });
          }, 500);
        }
      },
    },
    mounted() {
      this.scrollIntoFrom();
    },
  };
  </script>