<template>
  <div v-if="errored" class="error">
    <Error></Error>
  </div>
  <div v-if="loading" class="cargando">
    <Loading></Loading>
  </div>
  <div v-else>
    <MenuInterno/>
    <section id="bloqueTalleres">
      <TalleresPrincipal :dataTalleres="data"/>
    </section>
    <FooterInterno />
  </div>
</template>


<script>

import MenuInterno from "@/components/menuInternas/menuInternas.vue";
import TalleresPrincipal from "@/components/talleres/TalleresPrincipal.vue";
import FooterInterno from "@/components/footerInternas/footerInternas.vue";
import axios from "axios";
import Loading from "@/components/loading/Loading.vue";
import Error from "@/components/error/Error.vue";

export default {
    
    name: 'Talleres',
    components:{
    MenuInterno,
    TalleresPrincipal,
    FooterInterno,
    Loading,
    Error
},
inject: ['urlPeticion'], 
    data() {
    return {
      loading: true,
      errored: false,
      data: null,
    };
  },

  created() {
    this.getDatos();
  },

  methods: {
    async getDatos() {
      let urlJson = `${this.urlPeticion}/api/getTalleres/3/`;
      await axios
        .get(urlJson)
        .then((response) => {
          this.data = response.data;
        })
        .catch((error) => {
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>