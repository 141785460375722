<template>
  <div id="footer" class="footer">
      <div class="footer__slideTrack">
        <div v-for="item in datos.sponsors" :key="item" class="footer__slide">
          <picture>
              <source :srcset="item.url" type="image/png"> 
              <source :srcset="item.urlw" type="image/webp">
              <img class="footer__slide--img"  :src="item.urlw" :title="item.title" :alt="item.alt">
          </picture>      
        </div>
      </div>
      <div class="footer__slideTrack footer__slideTrack2">
        <div v-for="item in datos.sponsors" :key="item" class="footer__slide">
          <picture>
              <source :srcset="item.url" type="image/png"> 
              <source :srcset="item.urlw" type="image/webp">
              <img class="footer__slide--img"  :src="item.urlw" :title="item.title" :alt="item.alt">
          </picture>      
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "footerHomePrincipal",
  props: {
    footer: Object,
  },
  setup(props) {
    const datos = props.footer;
    
    return { datos };
   
  },
};
</script>