<template>
  <div class="cookies-background-block">
    <div id="cookies-panel" class="panel">
      <div class="cookie-banner-small">
        <div class="posicionTextoCookies">
          <p class="titulo-cookie">Tu privacidad es importante para nosotros</p>
          <p class="texto-cookie">
            En esta web utilizamos tecnologías como cookies propias y de
            terceros, y procesamos datos personales, como las direcciones IP y
            los identificadores de cookies, para personalizar los anuncios y el
            contenido según sus intereses, medir el rendimiento de los anuncios
            y el contenido y obtener información sobre las audencias que vieron
            los anuncios y dicho contenido. Puede consultar nuestra
            <a href="/cookies" class="mostrarCookies">Política de Cookies aquí.</a>
          </p>
        </div>
        <div class="contenedor_botones">
          <button id="allow-cookies" class="button expand" @click="aceptar()">
            ACEPTAR TODAS
          </button>
          <button id="denied-cookies" class="button expand" @click="rechazar()">
            RECHAZAR TODAS
          </button>
          <button id="conf-cookies" class="button expand" @click="configurar()">
            CONFIGURAR
          </button>
        </div>
      </div>
      <div class="cookie-banner-big">
        <div class="contenedor_txt_boton">
          <div class="opcionesCookies">
            <p id="back-cookie" @click="volver()">Volver</p>
            <p class="titulo-cookie">
              Tu privacidad es importante para nosotros
            </p>
            <p class="texto-cookie">
              Puedes configurar tus preferencias y elegir como quieres que tus
              datos sean utilizados para los siguientes propósitos.
            </p>
            <form class="cookies-conf">
              <div class="divOpciones">
                <ul>
                  <li class="round">
                    <input disabled checked type="checkbox" name="cookies-obligatorias" value="obligatorias"
                      id="obligatorias" />
                    <label for="obligatorias"></label>
                    <span class="titulo_span">Obligatorias</span>
                    <p class="cookies-options">
                      Cookies estrictamente necesarias para prestar un servicio
                      o contenido solicitado por el usuario. éstas se encargan
                      de aspectos técniso como identificar registros y gestionar
                      preferencias de herramientas en el uso, interacción y
                      localización del acceso a los contenidos.
                    </p>
                  </li>
                  <li class="round">
                    <input type="checkbox" name="cookies-estadistica" value="estadistica" id="estadisticas" />
                    <label for="estadisticas"></label>
                    <span class="titulo_span">Estadisticas</span>
                    <p class="cookies-options">
                      Estas cookies recogen información relativa al uso de la
                      página como la permanencia en página y los contenidos
                      visitdos por los lectores para medir su rendimiento y
                      mejorar la navegación.
                    </p>
                  </li>
                </ul>
              </div>
            </form>
          </div>
          <div class="contenedor_botones">
            <button id="denied-all" class="button expand" @click="rechazar()">
              RECHAZAR TODO
            </button>
            <button id="allow-all" class="button expand" @click="aceptar()">
              HABILITAR TODO
            </button>
            <button id="save-cookies" class="button expand" @click="guardar_y_salir()">
              GUARDAR Y SALIR
            </button>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
const $ = require("jquery");
export default {
  name: "mensaje cookies",
  mounted() {
    this.comprobacionCookies();
  },
  methods: {
    comprobacionCookies() {
      let OBLIGATORIAS = 1;
      let ESTADISTICA = 2;
      var cookieConsent = parseInt(this.getCookie("cookie-consent"));

      if (!cookieConsent) {
        $(".cookies-background-block").fadeIn(100);
      } else {
        switch (cookieConsent) {
          case OBLIGATORIAS:
            break;
          case ESTADISTICA:
            var cookieAnalytics1 = this.getCookie("_ga");
            var cookieAnalytics2 = this.getCookie("_gid");
            if (!cookieAnalytics1 || !cookieAnalytics2) {
              this.runAnalytics();
            }
            break;
          default:
            break;
        }
        this.closeCookies();
      }
    },
    configurar() {
      $(".cookie-banner-small").fadeOut(250, () => {
        if ($(window).width() > 750 && $(window).width() < 1024) {
          this.desplegarTablet();
        } else if ($(window).width() < 750) {
          this.desplegarMovil();
        } else {
          this.desplegarOrdenador();
        }
      });
    },
    aceptar() {
      this.runAnalytics();
      this.setCookie("cookie-consent", 2, 1);
      this.closeCookies();
    },
    rechazar() {
      this.setCookie("cookie-consent", 1, 1);
      this.closeCookies();
    },
    closeCookies() {
      if ($(".cookies-background-block").is(":visible")) {
        $("#cookies-panel, .cookies-background-block").fadeOut(100);
      } else {
        var intervalo = setInterval(function () {
          if ($(".cookies-background-block").is(":visible")) {
            this.clearInterval(intervalo);
            $("#cookies-panel, .cookies-background-block").fadeOut(100);
          }
        }, 100);
      }
    },
    volver() {
      $('.cookie-banner-big').fadeOut(250, () => {
        // $("#cookies-panel").css({'height': '650'});
        if ($(window).width() > 750 && $(window).width() < 1024) {
          this.retirarTablet();
        }
        else if ($(window).width() < 750) {
          this.retirarMovil();
        }
        else {
          this.retirarOrdenador();
        }
      });
    },
    guardar_y_salir() {
      if ($("#estadisticas").is(":checked")) {
        this.runAnalytics();
        this.setCookie("cookie-consent", 2, 1);
        this.closeCookies();
      }
      else {
        this.setCookie("cookie-consent", 1, 1);
        this.closeCookies();
      }
    },
    getCookie(cnombre) {
      var nombre = cnombre + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i].trim();
        if (c.indexOf(nombre) == 0) return c.substring(nombre.length, c.length);
      }
      return false;
    },
    setCookie(nombre, val, dias) {
      var d = new Date();
      d.setTime(d.getTime() + dias * 24 * 60 * 60 * 1000);
      var expires = "expires=" + d.toGMTString();
      document.cookie = nombre + "=" + val + "; " + expires + ";path=/";
    },
    runAnalytics() {
      var Url = window.location;

      if (
        Url.host == "congresolosorigenes.inspiriadental.com" ||
        Url.host == "www.congresolosorigenes.inspiriadental.com"
      ) {
        const tagManagerJs = document.createElement("script");
        tagManagerJs.setAttribute("async", "async");
        tagManagerJs.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=G-TVTH6EYFDP");

        const analyticsUrl = document.createElement("script");
        analyticsUrl.textContent = "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-TVTH6EYFDP');";

        document.head.appendChild(tagManagerJs);
        document.head.appendChild(analyticsUrl);
      }
    },
    desplegarTablet() {
      $(".cookie-banner-big").fadeIn(250);
    },
    desplegarOrdenador() {
      $(".cookie-banner-big").fadeIn(250);
    },
    desplegarMovil() {
      $(".cookie-banner-big").fadeIn(250);
    },
    retirarTablet() {
      $(".cookie-banner-small").fadeIn(250);
    },
    retirarOrdenador() {
      $(".cookie-banner-small").fadeIn(250);
    },
    retirarMovil() {
      $(".cookie-banner-small").fadeIn(250);
    },
  },
};
</script>
