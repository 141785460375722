<template>
  <section id="bloqueProgramaPrincipal">
    <div class="bannerFoto">
      <img class="bannerFoto__imgLogoHome"
        src="https://congresoknotgroup.inspiriadental.com/imagenes/programa/png/cabecera-programa-knotgroup-online.png"
        alt="Cabecera de la sección de programa del congreso de Knotgroup 2023"
        title="Cabecera de la sección de programa del congreso de Knotgroup 2023"/>
    </div>
    <div class="todaProgramacion">
      <div class="textoDestacado">
        <h1 class="textoDestacado__masImportante">Programa Congreso Knotgroup</h1>
       
      </div>
      <div v-for="(sala, index) in datos" :key="sala" class="sala" v-show="sala.titulo != 'SALA TALLERES IPD'">
        <div class="sala__contenido">
          <h2 class="sala__titulo">{{ sala.titulo }}</h2>

          <div v-if="sala.titulo == 'SALA TALLERES IPD'" class="sala_img">
            <picture>
              <!-- <img :src="sala.url_logo" alt="" srcset="" />  -->
              <img src="https://congresos-inspiria.s3.eu-west-1.amazonaws.com/knotgroupOrigenes/congreso/iconos/IPD.png"
                alt="Icono IPD Partner del Congreso Knotgroup" title="Icono IPD Partner del Congreso Knotgroup">
            </picture>
          </div>
        </div>


        <div v-for="actividad in sala.ponencias" :key="actividad"   class="sala__actividad" :class="[
          actividad.tipo == 9
            ? 'sala__actividad--azul'
            : [actividad.tipo == 3 ? 'sala__actividad--morado' : ''],
        ]"  v-show="actividad.titulo != 'TRASLADO AL PAZO LOS ESCUDOS' &&
            actividad.titulo != 'RECEPCIÓN ASISTENTES CENA' &&
            actividad.titulo != 'CENA DEL CONGRESO' " >
         
          <div v-if="actividad.tipo == 3" class="info">
           
            <div class="info_contenido">
              <p class="info__texto">{{ actividad.titulo }}</p>
              <br />
              <p class="info__texto">{{ actividad.descripcion }}</p>
              <br />
              <p class="info__ponentes">{{ actividad.ponentes }}</p>
            </div>
            <div v-if="actividad.webp !== ''" class="info_img">
              <picture>
                <source :srcset="actividad.webp" :alt="actividad.alt" :title="actividad.title" type="image/webp" loading="lazy">
                <img :src="actividad.url_logo" :alt="actividad.alt" :title="actividad.title" loading="lazy"/>
              </picture>
            </div>
          </div>
          <div v-else class="info">
            <div class="info_contenido">
              <p class="info__texto">{{ actividad.titulo }}</p>
              <br />
              <p class="info__texto">{{ actividad.descripcion }}</p>
              <br />
              <p class="info__ponentes">{{ actividad.ponentes }}</p>
            </div>

            <div v-if="actividad.webp !== ''" class="info_img">
              <picture>
                <source :srcset="actividad.webp" :alt="actividad.alt" :title="actividad.title" type="image/webp" loading="lazy">
                <img :src="actividad.url_logo" :alt="actividad.alt" :title="actividad.title" loading="lazy"/>
              </picture>
            </div>
          </div>
        </div>
        <div v-if="index < datos.length - 1" class="barraSeparadora"></div>
      </div>
    </div>
    <h3 class="hidden">Pedro Guitián</h3>
    <h3 class="hidden">Luis Mallo</h3>
    <h3 class="hidden">Ángel Carracedo</h3>
    <h4 class="hidden">EMS</h4>
    <h4 class="hidden">Normon Dental</h4>
    <h4 class="hidden">Createch</h4>
    <h4 class="hidden">Nobel Biocare</h4>
    <h4 class="hidden">FBA Consulting</h4>
  </section>
</template>

<script>
export default {
  name: "BaseBlock",
  props: {
    dataprograma: Object,
  },
  setup(props) {
    const datos = props.dataprograma;
    return { datos };
  },
  data: {
    contador: 0,
  },
};
</script>