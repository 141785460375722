<template>
  <section id="bloquePonentesPrincipal">
    <h1 class="hidden">Congreso Knotgroup</h1>
    <!-- hay que meter el div del bucle, queda a la espera del servicio, se ha repetido el div para maquetar -->
    <div class="bannerFoto">
      <picture>
        <source
          class="bannerFoto__imgLogoHome"
          srcset="https://s3.eu-west-1.amazonaws.com/congresoknotgroup.inspiriadental.com/imagenes/ponentes/webp/imagen-cabecera-ponentes-knotgroup.webp"
          type="image/webp"
          alt="Cabecera Ponentes Congreso Knotgroup Los Orígenes"
          title="Cabecera Ponentes Congreso Knotgroup Los Orígenes"
          loading="lazy">
        <img
          class="bannerFoto__imgLogoHome"
          src="https://s3.eu-west-1.amazonaws.com/congresoknotgroup.inspiriadental.com/imagenes/ponentes/jpg/imagen-cabecera-ponentes-knotgroup.jpg"
          alt="Cabecera Ponentes Congreso Knotgroup Los Orígenes"
          title="Cabecera Ponentes Congreso Knotgroup Los Orígenes"
          loading="lazy"/>
      </picture>
    </div>
    <div class="contenedorPonentes">
      <h2 class="hidden">Ponentes Congreso</h2>
      <p class="contenedorPonentes__texto">PONENTES</p>
      <h3 class="hidden">Los Orígenes</h3>
      <div class="gridponentes">
        <a v-for="ponente in datos" :key="ponente.id" class="ponente" :href="`/ponentes/${ponente.slug}`"  :title="ponente.title" v-show="ponente.id ">
          <picture>
            <source
              class="imgPonente"
              :srcset="ponente.webp"
              type="image/webp"
              :alt="ponente.alt"
              :title="ponente.title"
              loading="lazy">
            <img 
              class="imgPonente"
              :src="ponente.img"
              :alt="ponente.alt"
              :title="ponente.title"
              loading="lazy"/>
          </picture>
          <div class="textos">
            <picture class="textos__container">
              <source
              class="textos__fondo"
              srcset="https://s3.eu-west-1.amazonaws.com/congresoknotgroup.inspiriadental.com/imagenes/ponentes/webp/imagen-fondo-nombre-knotgroup.webp"
              type="image/webp"
              alt="Imagen Fondo Nombre Congreso Knotgroup Los Orígenes"
              title="Imagen Fondo Nombre Congreso Knotgroup Los Orígenes"
              loading="lazy">
              <img
              class="textos__fondo"
              src="https://s3.eu-west-1.amazonaws.com/congresoknotgroup.inspiriadental.com/imagenes/ponentes/jpg/imagen-fondo-nombre-knotgroup.jpg"
              alt="Imagen Fondo Nombre Congreso Knotgroup Los Orígenes"
              title="Imagen Fondo Nombre Congreso Knotgroup Los Orígenes"
              loading="lazy"/>
            </picture>
            <p class="textos__nombrePonente">{{ponente.nombre}}</p>
          </div>
        </a>  
      </div>
    </div>
    <h4 class="hidden">Vigo congreso</h4>
    <h4 class="hidden">Congreso medicina</h4>
    <h4 class="hidden">Odontología</h4>
    <h5 class="hidden">Pedro Guitián</h5>
  </section>
</template>

<script>
import { ref } from '@vue/reactivity';
  export default {
    name: "ContenidoPonentes",
    props: {
      dataponentes: Object,
    },
    setup(props) {
      const datos = ref(props.dataponentes);
      return { datos };
    },
    data: {
        contador: 0,
    }
  };
</script>